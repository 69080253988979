<template>
  <div id="Manage">
    <!-- 订单管理   住宿订单 -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel.sync="tabFormLabel"
                     :tableData="priceDate"
                     :activeTabName.sync="activeTabName"
                     @tabHandleClick="swichTab"
                     ref="elTable"></CommonTabForm>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(0, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(1, auditForm)">通 过</el-button>
      </div>
    </el-dialog>

    <!-- Dialog 对话框 备注表单-->
    <el-dialog :title="dialogFonfigRemarks.remarksName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigRemarks.isShowDialogVisible">
      <CommonForm :form="remarksForm"
                  class="dialogAuditForm"
                  :formLabel="remarksFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="success"
                   @click="clickSubmitRemarksData(remarksForm)">确 认</el-button>
      </div>
    </el-dialog>

    <!-- Dialog 对话框 拒绝表单-->
    <el-dialog :title="dialogFonfigRefund.refundName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigRefund.isShowDialogVisible">
      <CommonForm :form="refundForm"
                  class="dialogAuditForm"
                  :formLabel="refundFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="success"
                   @click="clickSubmitRefundData(refundForm)">确 认</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <!-- <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline">添加酒店/民宿</el-button> -->
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @clickEditData="clickEditData"
                       @clickInfoData='clickInfoData'
                       @clickWarningData='clickAuditData'
                       @clickRemarks='clickRemarks'
                       @addSubcategory='clickImplement'
                       @clickDeleteData='clickRefuseData'
                       @tabHandleClick="swichTable"
                       @getPage="getPage"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getHotelOrder, getHotelOrderDetail, confirmHotelOrder
  , AuditHotelOrder, RefuseHotelOrder, RemarkHotelOrder, implementHotelOrder
} from 'api/orderManagement'


import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonAmap from 'components/CommonAmap.vue'


export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm,
    CommonAmap
  },
  data () {
    return {
      //主页面组件布局
      imgIndex: [],//获取删除后的图片
      priceDate: [],//第二页中价格数据的数据
      tabIndex: 0,//存放tab标签页的下标
      searchData: '',//存放搜索的数据，分页时使用
      tableFormLabel: [
        {
          name: 'first',
          label: "待支付",
          type: 'table',
          total: 'total',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '70px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "已支付",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay',
            },
            {
              prop: 'operation',
              label: '操作',
              width: '115px',
              type: 'operation',
              children: [
                {
                  content: '拒绝',
                  type: 'danger'
                },
                {
                  content: '确认',
                  type: 'info'
                },
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'third',
          label: "已确认",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '100px',
              type: 'operation',
              children: [
                {
                  content: '入住',
                  type: 'addSub'
                },
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fourth',
          label: "已入住",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '90px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fifth',
          label: "已评价",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '90px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'sixth',
          label: "退款中",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '100px',
              type: 'operation',
              children: [
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'seventh',
          label: "已退款",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '90px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'eighth',
          label: "已取消",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'real_name',
              label: '联系人'
            },
            {
              prop: 'mobile',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '90px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '备注',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },

      ],

      //对话框 表单组件
      tabFormLabel: [
        {
          name: 'first',
          label: "订单信息",
          type: 'form',
          children: [ //form表单 组件   
            {
              model: 'id',
              label: '订单ID:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'order_no',
              label: '订单编号:',
              width: '500px',
              type: 'text'
            },
            {
              model: 'supplier_id',
              label: '供应商ID:',
              width: '300px',
              type: 'text',
              class: 'double'
            },
            {
              model: 'share_user_id',
              label: '分享用户ID:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'hotel_name',
              label: '酒店名称:',
              width: '500px',
              type: 'text'
            },
            {
              model: 'order_title',
              label: '订单标题:',
              width: '600px',
              type: 'text'
            },
            {
              model: 'day_num',
              label: '入住天数:',
              width: '313px',
              type: 'text'
            },
            {
              model: 'real_name',
              label: '预订人:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'order_status',
              label: '订单状态:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'integral',
              label: '积分:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'tour_amount',
              label: '旅游金额:',
              width: '341px',
              type: 'text'
            },
            {
              model: 'cash_coupon_sum',
              label: '现金劵金额:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'pay_type',
              label: '支付类型:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'total_price',
              label: '总价:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'order_type',
              label: '订单类型:',
              width: '315px',
              type: 'text'
            },
            {
              model: 'admin_id',
              label: '用户ID:',
              type: 'text',
              width: '300px'
            },
            {
              model: 'chg_amount',
              label: '优惠金额:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'source',
              label: '来源:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'coupon_ids',
              label: '优惠券ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'mobile',
              label: '手机:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'pay_time',
              label: '支付时间:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'pay_money',
              width: '300px',
              label: '付款:',
              type: 'text'
            },
            {
              model: 'check_time',
              width: '315px',
              label: '检查时间:',
              type: 'text'
            },
            {
              model: 'room_num',
              label: '房间数:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'create_time',
              width: '315px',
              label: '创建时间:',
              type: 'text'
            },
            {
              model: 'room_id',
              label: '房间ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'cancel_time',
              width: '358px',
              label: '取消时间:',
              type: 'text'
            },
            {
              model: 'finish_time',
              label: '完成交易时间:',
              width: '320px',
              type: 'text'
            },
            {
              model: 'start_date',
              label: '开始日期:',
              width: '356px',
              type: 'text'
            },
            {
              model: 'pay_trade_no',
              label: '付款交易编号:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'remarks',
              label: '备注:',
              width: '500px',
              type: 'text'
            },
            {
              // label: '退款信息  ',
              // width: '500px',
              type: 'text1',
              class: 'refund'
            },
            {
              model: 'refund_time',
              label: '退款时间:',
              width: '356px',
              type: 'text'
            },
            {
              model: 'refund_money',
              label: '退款金额:',
              width: '356px',
              type: 'text'
            },
            {
              model: 'refund_reason',
              width: '700px',
              label: '取消原因:',
              type: 'text'
            },
            {
              model: 'user_id',
              width: '300px',
              label: '管理员ID:',
              type: 'text'
            },
          ]
        },
        {
          name: 'second',
          label: "价格数据",
          type: 'table',
          //tab-table表单数据
          formTable: {
            date: '',
            price: '',
            cost: '',
            settlement_price: '',
            integral_price: '',
            rate: ''
          },
          tableLabel: [
            {
              prop: 'date',
              label: '日期',
              width: '140px'
            },
            {
              prop: 'price',
              label: '价格',
              width: '140px'
            },
            {
              prop: 'cost',
              label: '成本',
              width: '140px'
            },
            {
              prop: 'settlement_price',
              label: '结算价格',
              width: '140px'
            },
            {
              prop: 'integral_price',
              label: '价格',
              width: '140px'
            },
            {
              prop: 'rate',
              label: '分润比例',
            },
          ],
          tableConfig: {
            isShowLoading: false,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: false,
            //row key
            rowKey: 'date',
            //显示表格top按钮
            isShowTopBtn: false,
          }
        },
        {
          name: 'third',
          label: "用户信息",
          type: 'form',
          children: [
            {
              model: 'userphone',
              label: '手机号码:',
              width: '312px',
              type: 'text'
            },
            {
              model: 'username',
              label: '名字:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'facilitator_id',
              label: '服务商ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'userid',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'referrer_uid',
              label: '推荐人ID:',
              width: '312px',
              type: 'text'
            },
            {
              model: 'usernickname',
              label: '昵称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'vip',
              label: 'VIP:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'fourth',
          label: "酒店信息",
          type: 'form',
          children: [
            {
              model: 'address',
              label: '地址:',
              width: '700px',
              type: 'text'
            },
            {
              model: 'hotelName',
              label: '酒店名称:',
              width: '700px',
              type: 'text'
            },
            {
              model: 'hotelID',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'landlord_name',
              label: '联系人:',
              width: '328px',
              type: 'text'
            },
            {
              model: 'hoteltitle',
              label: '名称:',
              width: '313px',
              type: 'text'
            },
            {
              model: 'landlord_tel',
              label: '联系方式:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'level',
              label: '级别:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'room_no',
              label: '房间号:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'mode',
              label: '模式:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'fifth',
          label: '渠道来源',
          type: 'form',
          children: [
            {
              model: 'sourceAbb',
              label: '代理商简称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceID',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceNick',
              label: '代理商全称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceTelphone',
              label: '电话:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceName',
              label: '负责人名称:',
              width: '340px',
              type: 'text'
            },
            {
              model: 'sourcephone',
              label: '负责人电话:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'sixth',
          label: '供应商',
          type: 'form',
          children: [
            {
              model: 'supplierAbb',
              label: '供应商简称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'supplierID',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'supplierNick',
              label: '供应商全称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'supplierTelphone',
              label: '电话:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'supplierName',
              label: '负责人名称:',
              width: '340px',
              type: 'text'
            },
            {
              model: 'supplierphone',
              label: '负责人电话:',
              width: '300px',
              type: 'text'
            },
          ]
        }

      ],

      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '查看订单详情',
        editName: '查看订单详情',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核住宿订单
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      //form表单 审核
      auditForm: {
        order_no: '',
        remarks: ''
      },
      auditFormLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        },
      ],

      // 对话框 Dialog 备注 住宿订单
      dialogFonfigRemarks: {
        remarksName: '备注',
        isShowDialogVisible: false
      },
      //form表单 备注
      remarksForm: {
        order_no: '',
        remarks: ''
      },

      remarksFormLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '备注',
          type: 'textarea',
          width: '600px',
          required: true
        },
      ],

      // 对话框 Dialog 备注 住宿订单
      dialogFonfigRefund: {
        refundName: '拒绝',
        isShowDialogVisible: false
      },
      //form表单 备注
      refundForm: {
        order_no: '',
        refund: ''
      },

      refundFormLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '备注',
          type: 'textarea',
          width: '600px',
          required: true
        },
      ],

      //form表单 基础数据
      openForm: {
        //订单信息
        referrer_uid: '',
        facilitator_id: '',
        id: "",
        order_no: "",
        order_title: "",
        user_id: "",
        share_user_id: "",
        source: "",
        supplier_id: "",
        room_id: "",
        hotel_name: "",
        room_num: "",
        day_num: "",
        start_date: "",
        room_price_data: [
          {
            date: "",
            price: "",
            cost: "",
            settlement_price: "",
            integral_price: "",
            rate: []
          }
        ],
        room_friend_data: null,
        remarks: "",
        real_name: "",
        mobile: "",
        order_type: "",
        order_status: "",
        total_price: "",
        chg_amount: "",
        real_sum: "",
        balance: "",
        tour_amount: "",
        integral: "",
        cash_coupon_sum: "",
        coupon_ids: "",
        pay_type: "",
        pay_time: "",
        pay_trade_no: "",
        pay_money: "",
        check_time: "",
        finish_time: "",
        cancel_time: "",
        cancel_reason: "",
        refund_time: "",
        refund_money: "",
        refund_reason: "",
        create_time: "",
        admin_id: "",
        //用户信息
        userid: '',
        userphone: '',
        usernickname: '',
        username: '',
        vip: '',
        //酒店信息
        hotelID: '',
        hoteltitle: '',
        landlord_name: '',
        landlord_tel: '',
        address: '',
        hotelName: '',
        room_no: '',
        level: '',
        mode: '',
        //渠道来源
        sourceID: '',
        sourceAbb: '',
        sourceNick: '',
        sourceTelphone: '',
        sourceName: '',
        sourcephone: '',
        //供应商信息
        supplierID: '',
        supplierAbb: '',
        supplierNick: '',
        supplierTelphone: '',
        supplierName: '',
        supplierphone: '',
      },
      // form表单 搜索数据
      searchForm: {
        order_no: '',
        order_title: '',
        username: '',
        mobile: '',
        supplier_id: '',
        pay_type: ''
      },
      searchformLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '304px',
        },
        {
          model: 'order_title',
          label: '订单信息',
          width: '304px',
        },
        {
          model: 'username',
          label: '联系人',
          width: '304px',
        },
        {
          model: 'mobile',
          label: '联系方式',
          width: '304px',
        },
        {
          model: 'supplier_id',
          label: '供应商ID',
          width: '304px',
        },
        {
          model: 'pay_type',
          label: '支付方式',
          type: 'select',
          width: '230px',
          options: [
            { value: 0, label: '余额' },
            { value: 1, label: '抵用金' },
            { value: 2, label: '积分' },
            { value: 3, label: '微信' },
            { value: 4, label: '支付宝' },
            { value: 5, label: '银联' },
          ]
        },
      ],
      //内层Dialog
      inDialog: {
        title: '选择经纬度',
        isShowDialogVisible: false
      },

      // table 表格 基础数据
      tableData: [],
      // 酒店、民宿-全部-数据
      hotelAllDataObj: {},
      // catgory_id最大值
      MaxId: '',
      //待审核 或 正式运营 Name
      activeTableName: 'first',
      //Dialog新增/编辑 Name
      activeTabName: 'first',
      //Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      //提交保存信息的 按钮名称
      dialogBtnName: '下一步'
    }
  },
  method: {
  },
  mounted () {
    //初始table数据
    this.getHotelOrder();
  },
  watch: {
    //将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
          status: 'state',
        },
        0
      )
    },
    activeTabName: function () {
      console.log(this.activeTabName)
      if (this.activeTabName == "sixth") {
        this.dialogBtnName = '确认'
      } else {
        this.dialogBtnName = "下一步"
      }
    },
  },
  methods: {

    //住宿订单 请求接口
    getHotelOrder (data) {
      if (data) {
        getHotelOrder(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      } else {
        getHotelOrder({
          order_status: this.tabIndex,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      }

    },
    //住宿订单 详情
    getHotelOrderDetail (data) {
      return new Promise(resolve => {
        getHotelOrderDetail(data).then((res) => {
          resolve(res)
        })
      })
    },
    //审批退款订单  接口
    AuditHotelOrder (data) {
      return new Promise(resolve => {
        AuditHotelOrder(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    //拒绝住宿订单  接口
    RefuseHotelOrder (data) {
      return new Promise(resolve => {
        RefuseHotelOrder(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    //备注住宿订单  接口
    RemarkHotelOrder (data) {
      return new Promise(resolve => {
        RemarkHotelOrder(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    //获去住宿订单数据详情
    async clickEditData (row) {
      const data = {
        id: row.id
      }
      let result = await this.getHotelOrderDetail(data)
      let orderInfo = result.data.data.orderInfo
      let userInfo = result.data.data.userInfo
      let hotelInfo = result.data.data.hotelInfo
      let sourceInfo = result.data.data.sourceInfo
      let supplierInfo = result.data.data.supplierInfo
      console.log(result)
      this.openForm = {
        //订单信息
        id: orderInfo.id,
        order_no: orderInfo.order_no,
        order_title: orderInfo.order_title,
        user_id: orderInfo.user_id,
        share_user_id: orderInfo.share_user_id,
        source: orderInfo.source,
        supplier_id: orderInfo.supplier_id,
        room_id: orderInfo.room_id,
        hotel_name: orderInfo.hotel_name,
        room_num: orderInfo.room_num,
        day_num: orderInfo.day_num,
        start_date: orderInfo.start_date,
        remarks: orderInfo.remarks,
        real_name: orderInfo.real_name,
        mobile: orderInfo.mobile,
        order_type: orderInfo.order_type,
        order_status: orderInfo.order_status,
        total_price: orderInfo.total_price,
        chg_amount: orderInfo.chg_amount,
        real_sum: orderInfo.real_sum,
        balance: orderInfo.balance,
        tour_amount: orderInfo.tour_amount,
        integral: orderInfo.integral,
        cash_coupon_sum: orderInfo.cash_coupon_sum,
        coupon_ids: orderInfo.coupon_ids,
        pay_type: orderInfo.pay_type,
        pay_time: orderInfo.pay_time,
        pay_trade_no: orderInfo.pay_trade_no,
        pay_money: orderInfo.pay_money,
        check_time: orderInfo.check_time,
        finish_time: orderInfo.finish_time,
        cancel_time: orderInfo.cancel_time,
        cancel_reason: orderInfo.cancel_reason,
        refund_time: orderInfo.refund_time,
        refund_money: orderInfo.refund_money,
        refund_reason: orderInfo.refund_reason,
        create_time: orderInfo.create_time,
        admin_id: orderInfo.admin_id,

        //用户信息
        userid: userInfo.id,
        userphone: userInfo.phone,
        usernickname: userInfo.nickname,
        username: userInfo.name,
        vip: userInfo.vip,
        referrer_uid: userInfo.referrer_uid,
        facilitator_id: userInfo.facilitator_id,
        //酒店信息
        hotelID: hotelInfo.id,
        hoteltitle: hotelInfo.title,
        landlord_name: hotelInfo.landlord_name,
        landlord_tel: hotelInfo.landlord_tel,
        address: hotelInfo.address,
        hotelName: hotelInfo.name,
        room_no: hotelInfo.room_no,
        level: hotelInfo.level,
        mode: hotelInfo.mode,
        //渠道来源
        sourceID: sourceInfo.id,
        sourceAbb: sourceInfo.abbreviation,
        sourceNick: sourceInfo.nickname,
        sourceTelphone: sourceInfo.telephone,
        sourceName: sourceInfo.name,
        sourcephone: sourceInfo.phone,
        //供应商信息
        supplierID: supplierInfo.id,
        supplierAbb: supplierInfo.abbreviation,
        supplierNick: supplierInfo.nickname,
        supplierTelphone: supplierInfo.telephone,
        supplierName: supplierInfo.name,
        supplierphone: supplierInfo.phone,
      }
      //价格数据
      const priceArray = orderInfo.room_price_data.map(item => {
        return {
          date: item.date,
          price: item.price,
          cost: item.cost,
          settlement_price: item.settlement_price,
          integral_price: item.integral_price,
          rate: item.rate,
        }
      });

      this.priceDate = this.priceDate.concat(priceArray)
      var obj = {}
      this.priceDate = this.priceDate.reduce(function (item, next) {
        obj[next.date] ? '' : obj[next.date] = true && item.push(next)
        return item
      }, [])
      this.dialogFonfig.isShowDialogVisible = true
    },
    //已退款中的确认
    clickInfoData (row) {
      const data = {
        order_no: row.order_no
      }
      console.log(data)
      this.$confirm('确认这条记录吗？', '提示', { type: "success" }).then(() => {
        const data = {
          order_no: row.order_no
        }
        this.confirmHotelOrder(data)
        this.updataTableData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消确认'
        });
      })
    },

    //已确认中的 入住按钮操作
    clickImplement (row) {
      const data = {
        order_no: row.order_no
      }
      console.log(data)
      this.$confirm('让这条记录入住吗？', '提示', { type: "success" }).then(() => {
        const data = {
          order_no: row.order_no
        }
        this.implementHotelOrder(data)
        this.updataTableData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消确认'
        });
      })
    },

    //获得下列分类页的页数
    getPage () {
      // if (this.activeTableName == 'first') {
      //   this.getHotelOrder()
      // }
      if (this.searchData) {
        this.searchData.page = this.tableFormLabel[0].tableConfig.page,
          this.getHotelOrder(this.searchData)
      } else {
        this.getHotelOrder()
      }
    },


    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },


    //切换tab Dialog新增、编辑
    swichTab (tab) {
      console.log(tab.name)
      const name = tab.name
      this.activeTabName = name
    },
    //确认数据
    clickSubmitData (row) {
      const data = {
        order_no: row.order_no
      }
      console.log(row)
      if (this.activeTabName == 'first') {
        this.activeTabName = 'second'
      } else if (this.activeTabName == 'second') {
        this.activeTabName = 'third'
      } else if (this.activeTabName == 'third') {
        this.activeTabName = 'fourth'
      } else if (this.activeTabName == 'fourth') {
        this.activeTabName = 'fifth'
      } else if (this.activeTabName == 'fifth') {
        this.activeTabName = 'sixth'
      } else if (this.activeTabName == 'sixth') {
        this.dialogFonfig.isShowDialogVisible = false;
      }
    },
    //已支付中 确认订单
    confirmHotelOrder (data) {
      confirmHotelOrder(data).then(res => {
        const DATA = res.data
        console.log(res)
        if (DATA.code == 200) {
          this.$message.success('确认成功')
        } else {
          this.$message.error('确认失败')
        }
      })
      this.getHotelOrder()
    },
    //已确认中 入住订单
    implementHotelOrder (data) {
      implementHotelOrder(data).then(res => {
        const DATA = res.data
        console.log(res)
        if (DATA.code == 200) {
          this.$message.success('确认成功')
        } else {
          this.$message.error('确认失败')
        }
      })
      this.getHotelOrder()
    },


    //刷新表格数据
    updataTableData () {

      //带审核
      this.getHotelOrder();

    },

    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = "first"
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      this.dialogFonfigAudit.isShowDialogVisible = false
      this.dialogFonfigRemarks.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName

    },

    // 查询操作
    searchHotelProductToAudit (searchForm) {
      const data = {
        order_status: this.tabIndex,
        order_no: searchForm.order_no,
        order_title: searchForm.order_title,
        user_name: searchForm.username,
        mobile: searchForm.mobile,
        supplier_id: searchForm.supplier_id,
        pay_type: searchForm.pay_type
      }
      console.log(data)
      this.searchData = data
      this.getHotelOrder(data)

      console.log(searchForm)
    },
    //重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.order_no = ''
      searchForm.order_title = ''
      searchForm.username = ''
      searchForm.mobile = ''
      searchForm.supplier_id = ''
      searchForm.pay_type = ' '
      this.searchData = ''
      if (this.activeTableName == 'first') {
        this.getHotelOrder()
      }
    },

    //切换tab 
    swichTable (tab) {
      this.tabIndex = tab.index
      console.log(this.tabIndex)
      const name = tab.name
      this.activeTableName = name

      //刷新
      this.updataTableData();
    },

    //审核操作
    clickAuditData (row) {
      console.log(row)
      this.auditForm = {
        order_no: row.order_no,
        type: 'audit'
      }
      console.log(this.auditForm)
      this.dialogFonfigAudit.isShowDialogVisible = true;
    },

    //已支付中，拒绝订单
    clickRefuseData (row) {
      this.refundForm = {
        order_no: row.order_no,
        type: 'refuse'
      }
      this.dialogFonfigRefund.isShowDialogVisible = true;
    },
    //拒绝结果提交
    async clickSubmitRefundData (data) {
      if (data.type == 'refuse') {
        const DATA = {
          order_no: data.order_no,
          remarks: data.remarks
        }
        console.log(DATA)
        await this.RefuseHotelOrder(DATA).then(async (res) => {
          if (res.code == 200) {
            await this.updataTableData();
          }
        });//调用拒绝接口
      }
      this.dialogFonfigRefund.isShowDialogVisible = false;
    },
    //审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      console.log(data)
      if (data.type == 'audit') {
        const DATA = {
          order_no: data.order_no,
          is_pass: statue,
          remarks: data.remarks
        }
        await this.AuditHotelOrder(DATA).then(async (res) => {
          if (res.code == 200) {
            await this.updataTableData();
          }
        });//调用审核接口
      }

      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    //备注操作
    clickRemarks (row) {
      console.log(row)
      this.remarksForm = {
        order_no: row.order_no,
      }
      console.log(this.remarksForm)
      this.dialogFonfigRemarks.isShowDialogVisible = true;
    },
    //提交备注信息
    async clickSubmitRemarksData (data) {
      const DATA = {
        order_no: data.order_no,
        remarks: data.remarks
      }
      console.log(DATA)
      await this.RemarkHotelOrder(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData();
        }
      })
      this.dialogFonfigRemarks.isShowDialogVisible = false
    },

  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    // margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
}
/deep/ .el-dialog {
  width: 45%;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    // .el-form-item {
    //   border: 1px solid red;
    //   // width: 70%;
    //   // flex: 0 0 40%;
    //   // margin-left: 20px;
    // }
  }
  .el-form {
    // width: 100%;
  }
}
</style>